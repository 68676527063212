<template>
	<el-dialog
		title=""
		:visible.sync="createdVisible"
		width="850px"
		center
		:close-on-click-modal="false"
		@close="onResetForm('ruleForm')"
		:show-close="false"
	>
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}绩效填报</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="医疗机构" prop="medicalOrganizationId" class="w50 mr16">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.medicalOrganizationId"
					placeholder="请选择"
					@change="changeSelect($event, 'medicalOrganizationId')"
				>
					<el-option v-for="(item, index) in medicalOrganizaList" :key="index" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="填报年份" prop="reportYear" class="w50 mr0">
				<el-date-picker v-model="ruleForm.reportYear" type="year" placeholder="选择年" value-format="yyyy" :disabled="isShowDetail"> </el-date-picker>
			</el-form-item>
			<!-- <el-form-item label="年度诊疗人次数" prop="fakeThisYearPatientsTreatNumber" class="w50 mr0">
				<el-input v-model.trim="ruleForm.fakeThisYearPatientsTreatNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
			</el-form-item> -->
			<p class="titleName">诊疗人次增长率</p>
			<div class="modelClass">
				<el-form-item label="本年度诊疗人次数" prop="fakeThisYearPatientsTreatNumber" class="w50 mr16">
					<el-input v-model.trim="ruleForm.fakeThisYearPatientsTreatNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
				<el-form-item label="上年度诊疗人次数" prop="fakePreviousYearPatientsTreatedNumber" class="w50 mr0">
					<el-input
						v-model.trim="ruleForm.fakePreviousYearPatientsTreatedNumber"
						:disabled="isShowDetail"
						placeholder="请输入"
						type="number"
					></el-input>
				</el-form-item>
			</div>
			<p class="titleName">中医诊疗人次占比</p>
			<div class="modelClass">
				<el-form-item label="中医诊疗人次数" prop="fakeTcmTreatNumber" class="w50 mr16">
					<el-input v-model.trim="ruleForm.fakeTcmTreatNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
				<el-form-item label="总诊疗人次数" prop="fakeTotalTreatNumber" class="w50 mr0">
					<el-input v-model.trim="ruleForm.fakeTotalTreatNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
			</div>
			<el-form-item label="门诊中医非药物疗法诊疗人次" prop="fakeTcmNonDrugOutpatientNumber">
				<el-input v-model.trim="ruleForm.fakeTcmNonDrugOutpatientNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
			</el-form-item>
			<el-form-item label="出院人数" prop="fakeDischargedPatientNumber" class="w50 mr16">
				<el-input v-model.trim="ruleForm.fakeDischargedPatientNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
			</el-form-item>
			<el-form-item label="病床使用率" prop="fakeBedsUtilizationRate" class="w50 mr0">
				<el-input v-model.trim="ruleForm.fakeBedsUtilizationRate" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
			</el-form-item>
			<p class="titleName">65岁及以上老年人城乡社区规范健康管理服务(率)</p>
			<div class="modelClass">
				<el-form-item label="65以上老年人服务人数" prop="fakeSixtyfiveElderHealthServiceNumber" class="w50 mr16">
					<el-input
						v-model.trim="ruleForm.fakeSixtyfiveElderHealthServiceNumber"
						:disabled="isShowDetail"
						placeholder="请输入"
						type="number"
					></el-input>
				</el-form-item>
				<el-form-item label="区域总65以上人数" prop="fakeRegionSixtyfiveElderHealthServiceNumber" class="w50 mr0">
					<el-input
						v-model.trim="ruleForm.fakeRegionSixtyfiveElderHealthServiceNumber"
						:disabled="isShowDetail"
						placeholder="请输入"
						type="number"
					></el-input>
				</el-form-item>
			</div>
			<p class="titleName">高血压患者基层规范管理服务率(率)</p>
			<div class="modelClass">
				<el-form-item label="高血压患者健康管理服务的人数" prop="fakeHypertensionHealthServiceNumber" class="w50 mr16">
					<el-input
						v-model.trim="ruleForm.fakeHypertensionHealthServiceNumber"
						:disabled="isShowDetail"
						placeholder="请输入"
						type="number"
					></el-input>
				</el-form-item>
				<el-form-item label="年内转区内已管理的高血压患者人数" prop="fakeHypertensionTotalNumber" class="w50 mr0">
					<el-input v-model.trim="ruleForm.fakeHypertensionTotalNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
			</div>
			<p class="titleName">管理人群血压控制率</p>
			<div class="modelClass">
				<el-form-item label="最近一次随访血压达标人数" prop="fakeLatestHypertensionHealthServiceNumber" class="w50 mr16">
					<el-input
						v-model.trim="ruleForm.fakeLatestHypertensionHealthServiceNumber"
						:disabled="isShowDetail"
						placeholder="请输入"
						type="number"
					></el-input>
				</el-form-item>
				<el-form-item label="年内已管理的高血压患者人数" prop="fakeThisYearHypertensionHealthServiceNumber" class="w50 mr0">
					<el-input
						v-model.trim="ruleForm.fakeThisYearHypertensionHealthServiceNumber"
						:disabled="isShowDetail"
						placeholder="请输入"
						type="number"
					></el-input>
				</el-form-item>
			</div>
			<p class="titleName">2型糖尿病患者基层规范管理服务率</p>
			<div class="modelClass">
				<el-form-item label="2型尿病患者健康管理服务人数" prop="fakeDiabetesHealthServiceNumber" class="w50 mr16">
					<el-input v-model.trim="ruleForm.fakeDiabetesHealthServiceNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
				<el-form-item label="年内辖区内已管理的2型糖尿病患者人数" prop="fakeDiabetesTotalNumber" class="w50 mr0">
					<el-input v-model.trim="ruleForm.fakeDiabetesTotalNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
			</div>
			<p class="titleName">管理人群血糖控制率</p>
			<div class="modelClass">
				<el-form-item label="年内最近一次随访空腹血糖达标人数" prop="fakeLatestDiabetesHealthServiceNumber" class="w50 mr16">
					<el-input
						v-model.trim="ruleForm.fakeLatestDiabetesHealthServiceNumber"
						:disabled="isShowDetail"
						placeholder="请输入"
						type="number"
					></el-input>
				</el-form-item>
				<el-form-item label="年内已管理的2型糖尿病患者人数" prop="fakeThisYearDiabetesHealthServiceNumber" class="w50 mr0">
					<el-input
						v-model.trim="ruleForm.fakeThisYearDiabetesHealthServiceNumber"
						:disabled="isShowDetail"
						placeholder="请输入"
						type="number"
					></el-input>
				</el-form-item>
			</div>
			<p class="titleName">严重精神障碍患者规范管理率</p>
			<div class="modelClass">
				<el-form-item label="年内辖区内按照规范要求进行管理的严重精神碍患者人数" prop="fakeLatestPsychosisHealthServiceNumber" class="w50 mr16">
					<el-input
						v-model.trim="ruleForm.fakeLatestPsychosisHealthServiceNumber"
						:disabled="isShowDetail"
						placeholder="请输入"
						type="number"
					></el-input>
				</el-form-item>
				<el-form-item label="年内辖内登记在册的确诊严重精神障碍患者人数" prop="fakeThisYearPsychosisHealthServiceNumber" class="w50 mr0">
					<el-input
						v-model.trim="ruleForm.fakeThisYearPsychosisHealthServiceNumber"
						:disabled="isShowDetail"
						placeholder="请输入"
						type="number"
					></el-input>
				</el-form-item>
			</div>
			<p class="titleName">家庭医生签约服务全人群覆盖率</p>
			<div class="modelClass">
				<el-form-item label="签约居民人数" prop="fakeContractedResidentsNumber" class="w50 mr16">
					<el-input v-model.trim="ruleForm.fakeContractedResidentsNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
				<el-form-item label="年末服务人口数" prop="fakeThisYearServiceResidentsNumber" class="w50 mr0">
					<el-input v-model.trim="ruleForm.fakeThisYearServiceResidentsNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
			</div>
			<p class="titleName">医师日均担负诊疗人次</p>
			<div class="modelClass">
				<el-form-item label="诊疗人次数" prop="fakeVisitsNumber" class="w50 mr16">
					<el-input v-model.trim="ruleForm.fakeVisitsNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
				<el-form-item label="平均医师人数" prop="fakeAveragePhysiciansNumber" class="w50 mr0">
					<el-input v-model.trim="ruleForm.fakeAveragePhysiciansNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
			</div>
			<p class="titleName">医疗服务收入占比</p>
			<div class="modelClass">
				<el-form-item label="医疗服务收入(不含药品、耗材、检查检验收入)" prop="fakeMedicalServiceRevenue" class="w50 mr16">
					<el-input v-model.trim="ruleForm.fakeMedicalServiceRevenue" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
				<el-form-item label="医疗收入" prop="fakeMedicalRevenue" class="w50 mr0">
					<el-input v-model.trim="ruleForm.fakeMedicalRevenue" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
			</div>
			<p class="titleName">每职工平均本期盈余</p>
			<div class="modelClass">
				<el-form-item label="本期收入" prop="fakeCurrentIncome" class="w50 mr16">
					<el-input v-model.trim="ruleForm.fakeCurrentIncome" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
				<el-form-item label="本期费用(本期支出)" prop="fakeCurrentExpenses" class="w50 mr0">
					<el-input v-model.trim="ruleForm.fakeCurrentExpenses" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
				<el-form-item label="平均职工人数" prop="fakeAverageWorkerNumber" class="w50 mr16">
					<el-input v-model.trim="ruleForm.fakeAverageWorkerNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
			</div>
			<p class="titleName">人员支出占业务支出比例</p>
			<div class="modelClass">
				<el-form-item label="人员支出" prop="fakePersonnelExpenditure" class="w50 mr16">
					<el-input v-model.trim="ruleForm.fakePersonnelExpenditure" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
				<el-form-item label="业务支出" prop="fakeBusinessExpense" class="w50 mr0">
					<el-input v-model.trim="ruleForm.fakeBusinessExpense" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
			</div>
			<p class="titleName">执业医师占比</p>
			<div class="modelClass">
				<el-form-item label="年末执业医师数" prop="fakeEndYearLicensedPhysiciansNumber" class="w50 mr16">
					<el-input
						v-model.trim="ruleForm.fakeEndYearLicensedPhysiciansNumber"
						:disabled="isShowDetail"
						placeholder="请输入"
						type="number"
					></el-input>
				</el-form-item>
				<el-form-item label="在岗职工人数" prop="fakeEmployeesNumber" class="w50 mr0">
					<el-input v-model.trim="ruleForm.fakeEmployeesNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
			</div>
			<p class="titleName">医护比</p>
			<div class="modelClass">
				<el-form-item label="年末执业(助理)医师数" prop="fakeEndYearPracticingAssistantPhysiciansNumber" class="w50 mr16">
					<el-input
						v-model.trim="ruleForm.fakeEndYearPracticingAssistantPhysiciansNumber"
						:disabled="isShowDetail"
						placeholder="请输入"
						type="number"
					></el-input>
				</el-form-item>
				<el-form-item label="年末注册护士数" prop="fakeEndYearRegisteredNursesNumber" class="w50 mr0">
					<el-input v-model.trim="ruleForm.fakeEndYearRegisteredNursesNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
			</div>
			<p class="titleName">中医类别医师占比</p>
			<div class="modelClass">
				<el-form-item label="中医类别执业(助理)医师数" prop="fakeTcmRegisteredNursesNumber" class="w50 mr16">
					<el-input v-model.trim="ruleForm.fakeTcmRegisteredNursesNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
				<el-form-item label="同期基层医疗卫生机构执业(助)医师数" prop="fakeOrganizationTcmRegisteredNursesNumber" class="w50 mr0">
					<el-input
						v-model.trim="ruleForm.fakeOrganizationTcmRegisteredNursesNumber"
						:disabled="isShowDetail"
						placeholder="请输入"
						type="number"
					></el-input>
				</el-form-item>
			</div>
			<p class="titleName">高级职称卫生技术人员占比</p>
			<div class="modelClass">
				<el-form-item label="高级职称的卫生技术人员数" prop="fakeSeniorProfessionalseniorNumber" class="w50 mr16">
					<el-input v-model.trim="ruleForm.fakeSeniorProfessionalseniorNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
				<el-form-item label="同期卫生技术人员总数" prop="fakeProfessionalseniorNumber" class="w50 mr0">
					<el-input v-model.trim="ruleForm.fakeProfessionalseniorNumber" :disabled="isShowDetail" placeholder="请输入" type="number"></el-input>
				</el-form-item>
			</div>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	data() {
		return {
			createdVisible: false,
			saveLoading: false,
			ruleForm: {
				medicalOrganizationId: '',
				reportYear: '',
				fakeThisYearPatientsTreatNumber: '',
				fakePreviousYearPatientsTreatedNumber: '',
				fakeTcmTreatNumber: '',
				fakeTotalTreatNumber: '',
				fakeTcmNonDrugOutpatientNumber: '',
				fakeDischargedPatientNumber: '',
				fakeBedsUtilizationRate: '',
				fakeSixtyfiveElderHealthServiceNumber: '',
				fakeRegionSixtyfiveElderHealthServiceNumber: '',
				fakeHypertensionHealthServiceNumber: '',
				fakeHypertensionTotalNumber: '',
				fakeLatestHypertensionHealthServiceNumber: '',
				fakeThisYearHypertensionHealthServiceNumber: '',
				fakeDiabetesHealthServiceNumber: '',
				fakeDiabetesTotalNumber: '',
				fakeLatestDiabetesHealthServiceNumber: '',
				fakeThisYearDiabetesHealthServiceNumber: '',
				fakeLatestPsychosisHealthServiceNumber: '',
				fakeThisYearPsychosisHealthServiceNumber: '',
				fakeContractedResidentsNumber: '',
				fakeThisYearServiceResidentsNumber: '',
				fakeVisitsNumber: '',
				fakeAveragePhysiciansNumber: '',
				fakeMedicalServiceRevenue: '',
				fakeMedicalRevenue: '',
				fakeCurrentIncome: '',
				fakeCurrentExpenses: '',
				fakeAverageWorkerNumber: '',
				fakePersonnelExpenditure: '',
				fakeBusinessExpense: '',
				fakeEndYearLicensedPhysiciansNumber: '',
				fakeEmployeesNumber: '',
				fakeEndYearPracticingAssistantPhysiciansNumber: '',
				fakeEndYearRegisteredNursesNumber: '',
				fakeTcmRegisteredNursesNumber: '',
				fakeOrganizationTcmRegisteredNursesNumber: '',
				fakeSeniorProfessionalseniorNumber: '',
				fakeProfessionalseniorNumber: '',
			},
			medicalOrganizaList: [],
			rules: {
				medicalOrganizationId: [{ required: true, message: '请选择乡镇卫生院名称', trigger: 'blur' }],
				reportYear: [{ required: true, message: '请选择填报年份', trigger: 'blur' }],
				fakeThisYearPatientsTreatNumber: [{ required: true, message: '请输入本年度诊疗人次数', trigger: 'blur' }],
				fakePreviousYearPatientsTreatedNumber: [{ required: true, message: '请输入上年度诊疗人次数', trigger: 'blur' }],
				fakeTcmTreatNumber: [{ required: true, message: '请输入中医诊疗人次数', trigger: 'blur' }],
				fakeTotalTreatNumber: [{ required: true, message: '请输入总诊疗人次数', trigger: 'blur' }],
				fakeTcmNonDrugOutpatientNumber: [{ required: true, message: '请输入门诊中医非药物疗法诊疗人次', trigger: 'blur' }],
				fakeDischargedPatientNumber: [{ required: true, message: '请输入出院人数', trigger: 'blur' }],
				fakeBedsUtilizationRate: [{ required: true, message: '请输入病床使用率', trigger: 'blur' }],
				fakeSixtyfiveElderHealthServiceNumber: [{ required: true, message: '请输入65以上老年人服务人数', trigger: 'blur' }],
				fakeRegionSixtyfiveElderHealthServiceNumber: [{ required: true, message: '请输入区域总65以上人数', trigger: 'blur' }],
				fakeHypertensionHealthServiceNumber: [{ required: true, message: '请输入高血压患者健康管理服务的人数', trigger: 'blur' }],
				fakeHypertensionTotalNumber: [{ required: true, message: '请输入年内辖区内已管理的高血压患者人数', trigger: 'blur' }],
				fakeLatestHypertensionHealthServiceNumber: [{ required: true, message: '请输入最近一次随访血压达标人数', trigger: 'blur' }],
				fakeThisYearHypertensionHealthServiceNumber: [{ required: true, message: '请输入年内已管理的高血压患者人数', trigger: 'blur' }],
				fakeDiabetesHealthServiceNumber: [{ required: true, message: '请输入2型尿病患者健康管理服务人数', trigger: 'blur' }],
				fakeDiabetesTotalNumber: [{ required: true, message: '请输入辖区内已管理的2型糖尿病患者人数', trigger: 'blur' }],
				fakeLatestDiabetesHealthServiceNumber: [{ required: true, message: '请输入年内最近一次随访空腹血糖达标人数', trigger: 'blur' }],
				fakeThisYearDiabetesHealthServiceNumber: [{ required: true, message: '请输入年内已管理的2型糖尿病患者人数', trigger: 'blur' }],
				fakeLatestPsychosisHealthServiceNumber: [{ required: true, message: '请输入规范要求进行管理的严重精神碍患者人数', trigger: 'blur' }],
				fakeThisYearPsychosisHealthServiceNumber: [{ required: true, message: '请输入登记在册的确诊严重精神障碍患者人数', trigger: 'blur' }],
				fakeContractedResidentsNumber: [{ required: true, message: '请输入签约居民人数', trigger: 'blur' }],
				fakeThisYearServiceResidentsNumber: [{ required: true, message: '请输入年末服务人口数', trigger: 'blur' }],
				fakeVisitsNumber: [{ required: true, message: '请输入诊疗人次数', trigger: 'blur' }],
				fakeAveragePhysiciansNumber: [{ required: true, message: '请输入平均医师人数', trigger: 'blur' }],
				fakeMedicalServiceRevenue: [{ required: true, message: '请输入医疗服务收入', trigger: 'blur' }],
				fakeMedicalRevenue: [{ required: true, message: '请输入医疗收入', trigger: 'blur' }],
				fakeCurrentIncome: [{ required: true, message: '请输入本期收入', trigger: 'blur' }],
				fakeCurrentExpenses: [{ required: true, message: '请输入本期费用(本期支出)', trigger: 'blur' }],
				fakeAverageWorkerNumber: [{ required: true, message: '请输入平均职工人数', trigger: 'blur' }],
				fakePersonnelExpenditure: [{ required: true, message: '请输入人员支出', trigger: 'blur' }],
				fakeBusinessExpense: [{ required: true, message: '请输入业务支出', trigger: 'blur' }],
				fakeEndYearLicensedPhysiciansNumber: [{ required: true, message: '请输入年末执业医师数', trigger: 'blur' }],
				fakeEmployeesNumber: [{ required: true, message: '请输入在岗职工人数', trigger: 'blur' }],
				fakeEndYearPracticingAssistantPhysiciansNumber: [{ required: true, message: '请输入年末执业(助理)医师数', trigger: 'blur' }],
				fakeEndYearRegisteredNursesNumber: [{ required: true, message: '请输入年末注册护士数', trigger: 'blur' }],
				fakeTcmRegisteredNursesNumber: [{ required: true, message: '请输入中医类别执业(助理)医师数', trigger: 'blur' }],
				fakeOrganizationTcmRegisteredNursesNumber: [{ required: true, message: '请输入同期基层医疗卫生机构执业(助)医师数', trigger: 'blur' }],
				fakeSeniorProfessionalseniorNumber: [{ required: true, message: '请输入高级职称的卫生技术人员数', trigger: 'blur' }],
				fakeProfessionalseniorNumber: [{ required: true, message: '请输入同期卫生技术人员总数', trigger: 'blur' }],
			},
			link: '',
		};
	},
	props: ['isShowDetail'],
	methods: {
		init({ link, row }) {
			this.createdVisible = true;
			this.getMedicalOrganizaList();
			this.link = link;
			if (row) {
				this.getDetails(link);
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			//新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		getDetails(link) {
			this.$http
				.get(link)
				.then((res) => {
					if (res.data && res.data.success) {
						this.ruleForm = res.data.collection[0] || {};
						this.ruleForm.reportYear = res.data.collection[0].reportYear?.slice(0, 4) || '';
						this.ruleForm.medicalOrganizationId = res.data.collection[0].medicalOrganization?.id || '';
					}
				})
				.catch((e) => {});
		},

		// 获取医疗机构
		getMedicalOrganizaList() {
			let data = {
				current: 1,
				size: 99999,
			};
			this.$http
				.get(this.api['MedicalOrganizations#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						this.medicalOrganizaList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		onResetForm(formName) {
			this.createdVisible = false;
			// this.$refs.ruleForm = {};
			this.$refs[formName].resetFields();
		},
		//保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let params = {
						...this.ruleForm,
						reportYear: this.ruleForm.reportYear ? this.ruleForm.reportYear + '-01-01 00:00:00' : null,
					};
					let queryApi = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					queryApi
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.saveLoading = false;
								this.onResetForm('ruleForm');
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				} else {
					this.$nextTick(() => {
						let isError = document.getElementsByClassName('is-error');
						// var a = $(isError[0]).find("input") //this.$refs.cmd.$el.querySelector('input')
						// a.focus()  光标定位到所在input
						isError[0].scrollIntoView({
							// 滚动到指定节点
							// 值有start,center,end，nearest
							block: 'center',
							// 值有auto、instant,smooth，缓动动画
							behavior: 'smooth',
						});
					});
					return false;
				}
			});
		},

		changeSelect(val, type) {
			this.ruleForm[type] = val;
			this.$forceUpdate();
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		margin-bottom: 20px;
		.el-select,
		.el-input {
			width: 100%;
		}
	}
	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
		color: #303133;
	}
}
.titleName {
	line-height: 14px;
	height: 14px;
	margin-bottom: 8px;
	color: #303133;
}
.modelClass {
	border: 1px solid #dcdfe6;
	border-radius: 4px;
	padding: 16px;
	padding-bottom: 0;
	margin-bottom: 12px;
	/deep/ .el-form-item {
		margin-bottom: 20px;
	}
}
/deep/ .el-button {
	margin-left: 16px;
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
/deep/ .el-textarea__inner {
	min-height: 64px !important;
	line-height: 18px !important;
	padding: 5px 12px !important;
}
</style>